import * as React from "react"
import { Link } from "gatsby-plugin-react-i18next"
import logo from "../../images/logo.svg"
import { useTranslation } from "react-i18next"
import BuildDateTime from "../build-time"
const FooterComponent = ({ navigation, personalDataURL, cookieURL, mediaKit }) => {
    const { t } = useTranslation();
    return (
        <div className="border-top  mt-16">
            <div className="container grid grid-cols-footer p-14 lg:flex lg:flex-col">
                <div>
                <a className="mb-0 lg:mb-6" href={`http://www.strasbourg.eu/`}><img loading="lazy" alt="Logo of Strasbourg" src={logo} className="max-w-250-px mb-5"></img></a>
                </div>
                <div className="flex flex-col items-start gap-4 ">
                    {navigation.map(link => (<React.Fragment key={link.headerName || link.url}>{link.url && <Link className="link-header" activeClassName="link-header font-bold " to={link.url}>{link.name}</Link>}</React.Fragment>))}
                </div>
            </div>
            <div className="container flex gap-10 pb-5 justify-center flex-wrap lg:gap-3">
                <Link to="/legal-notice"><strong>{t("legal_mention")}</strong></Link>
                <a href={personalDataURL}><strong>{t("personal_data")}</strong></a>
                <a href={cookieURL}><strong>{t("cookie_policy")}</strong></a>
                <button href="#" onClick={() => window.tarteaucitron.userInterface.openPanel()}><strong>{t("cookie_manager")}</strong></button>
                <Link to="/accessibility"><strong>{t("accessibility")}</strong></Link>
                <Link to="/sitemap"><strong>{t("roadmap_site")}</strong></Link>
                {mediaKit?  <a href={mediaKit} target="_blank" rel="noreferrer"><strong>{t("media_kit")}</strong></a> : ""}
            </div>
            <BuildDateTime />
        </div>

    )
}

export default FooterComponent;