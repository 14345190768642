import { Link } from "gatsby-plugin-react-i18next";
import * as React from "react"
import { Button } from "@mui/material";
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';

const LinkButton = ({ to, className, children, external, variant="contained", ...otherAttr }) => {
    return (
        <span>
            {external ?
                <a href={to}>
                    <Button role="link" aria-label={children} variant={variant} color="secondary" endIcon={<ArrowForwardIosOutlinedIcon></ArrowForwardIosOutlinedIcon>}>{children}</Button>
                </a>
            :
                <Button component={Link} to={to} role="link" aria-label={children} variant={variant} color="secondary" endIcon={<ArrowForwardIosOutlinedIcon></ArrowForwardIosOutlinedIcon>} {...otherAttr}>{children}</Button>
            }
        </span>
    )
}

export default LinkButton;
