import * as React from "react"
import { useStaticQuery, graphql } from "gatsby";

const BuildDateTime = () => {
  const data = useStaticQuery(graphql`
    query {
      site {
        buildTime(formatString: "MMMM Do, YYYY, h:mm a")
      }
    }
  `);

  return (
    <p className="hidden" >Site built on: {data.site.buildTime} (GMT Time)</p>
  );
};

export default BuildDateTime;