import * as React from "react"
import LinkButton from "../components/elements/LinkButton"
import parse, { domToReact } from 'html-react-parser';
const parserOption = {
    replace: (domNode) => {
      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'snr-link-btn' &&
        domNode.attribs.href
      ) {
        const { href, ...otherProps } = domNode.attribs;
        return (<LinkButton to={href} className="inline-block" external>{domToReact(domNode.children)}</LinkButton>) ;
      }

      if (
        domNode.name === 'a' &&
        domNode.attribs.class === 'snr-outline-link-btn' &&
        domNode.attribs.href
      ) {
        const { href, ...otherProps } = domNode.attribs;
        return (<LinkButton to={href} className="inline-block" variant="outlined" external>{domToReact(domNode.children)}</LinkButton>) ;
      }
    },
  };

export default function HtmlParser(htmlContent) {
  return parse(htmlContent, parserOption)
}