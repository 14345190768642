import { MenuItem, Select } from "@mui/material";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { useTranslation } from "gatsby-plugin-react-i18next";
import * as React from "react"
import logo from "../../images/logo.svg"
import logoNR from "../../images/icon.png"
import logoSENR from "../../images/icon-senr.png"
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import { ExpandMore, ExpandLess } from "@mui/icons-material";

import MenuIcon from '@mui/icons-material/Menu';
import { IconButton, Button } from '@mui/material';
const NavBar = ({ navigation, navigationWithoutContact, banner, showPartner = false, showSearch = true, linkPass = null, isSENR = false }) => {
    const { t } = useTranslation();
    const { language, languages, changeLanguage } = useI18next();
    const [isNavOpen, setNavOpen] = React.useState(false);

    const topSubMenu = banner ? 182:139;

    const [sublinksOpened, setSublinksOpened] = React.useState([]);
    const toggleSublink = (value) => {
        const index = sublinksOpened.indexOf(value);

        if (index === -1) {
            sublinksOpened.push(value);
        } else {
            sublinksOpened.splice(index, 1);
        }
        setSublinksOpened([...sublinksOpened]);
    }

    const onEnter = (event) => {
        if (event.key === "Enter") {
            onChangeLanguage(event)
        }
    }
    const onChangeLanguage = (event) => {
        window.tarteaucitronForceLanguage = event.target.dataset.value;
        changeLanguage(event.target.dataset.value)
    }
    const onChangeLanguageMobile = (event) => {
        window.tarteaucitronForceLanguage = event.target.value;
        changeLanguage(event.target.value)
    }

    return (
        <div>
            <div className="items-center lg:hidden flex justify-between border-bottom ">
                <a href={`http://www.strasbourg.eu/`}>
                    <img className="max-w-250-px m-2.5" loading="lazy" alt="Logo Strasbourg.eu" src={logo} />
                </a>
                <div className="flex items-center h-58-px">
                {isSENR ? (<Link to={`/`} aria-label="Logo Numérique Responsable"><img loading="lazy" className="lg:ml-5 flex mr-9" alt="Logo Numérique Responsable" width={"50"} height="50" src={logoNR}></img></Link>) : "" }
                    {showPartner ? (<Link to="/partenaires" className="link-header mx-6 h-full flex items-center" key={t("menu_partners")} activeClassName="font-bold link-header">{t("menu_partners")}</Link>) : ""}
                    <Link to="/contact" className="link-header mx-6 h-full flex items-center" key={t("menu_contact")} activeClassName="font-bold link-header">{t("menu_contact")}</Link>
                </div>
            </div>
            <div className="flex lg:flex-col-reverse flex-col">
                {banner &&
                    <div className="text-orange-nr text-center border-bottom p-3 lg:hidden">
                        {banner}
                    </div>
                }
                <div className="border-bottom">
                <div className="navbar-container lg:justify-start justify-center  container">
                    <Link to={isSENR ? `/senr` : `/`} aria-label="Logo Numérique Responsable"><img loading="lazy" className="lg:ml-5 flex mr-9" alt="Logo Numérique Responsable" width={isSENR ? "155" : "90"} height="90" src={isSENR ? logoSENR : logoNR}></img></Link>
                    <div className="gap-13 mr-3 items-center justify-evenly flex-grow flex lg:hidden">
                        {navigationWithoutContact.map(link => {
                            if (link.headerName !== undefined) {
                                const sublinks = link.sublinks.map(sublink => (
                                    <li style={{ padding: 20 }} className="border-bottom" key={sublink.url + sublink.name}>
                                        <Link to={sublink.url}>{sublink.name}</Link>
                                    </li>
                                ));

                                const menuElementWithSublinks = (
                                    <div key={link.headerName} className="flex group" style={{
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: 90
                                    }}>
                                        <div className="link-header">
                                            {link.headerName}
                                            <ul
                                                className="hidden group-hover:flex"
                                                style={{
                                                    position: "absolute",
                                                    marginTop: 36,
                                                    backgroundColor: "#31455D",
                                                    transform: "translateX(-20px)",
                                                    flexDirection: "column",
                                                    fontSize: 16,
                                                    top: topSubMenu,
                                                    zIndex: 2
                                                }}
                                            >
                                                {sublinks}
                                            </ul>
                                        </div>
                                    </div>
                                );
                                return menuElementWithSublinks;
                            }
                            else {
                                return (<Link className="link-header" key={link.url + link.name} activeClassName="font-bold link-header" to={link.url}>{link.name}</Link>)
                            }
                        })}
                        

                        {linkPass != null ? (<a href={linkPass}>
                    <Button role="link" color="secondary" variant="contained" >{t('register-pass')}</Button>
                </a>) : ""}
                    </div>
                    {showSearch ? (<Link to="/search" id="search-desktop" alt="search" aria-label="search"><SearchOutlinedIcon></SearchOutlinedIcon></Link>) : ""}
                

                    <div className="right-0 float-right mr-4 hidden lg:flex lg:absolute">
                        <IconButton aria-label="Menu" onClick={() => setNavOpen(!isNavOpen)} ><MenuIcon></MenuIcon></IconButton>
                    </div>
                </div>
                </div>
              
            </div>

            <div className={`flex flex-col gap-2 py-2 items-center border-b border-white ${isNavOpen ? "" : "hidden"} nav-mobile`}>
                {navigation.map(link => {
                    if (link.headerName !== undefined) {
                        const showSublinks = sublinksOpened.includes(link.headerName);

                        const sublinks = link.sublinks.map(sublink => (
                            <Link key={sublink.url + sublink.name} to={sublink.url}>{sublink.name}</Link>
                        ));

                        return (
                            <div key={link.headerName} className="link-header" style={{ textAlign: "center", width: "100%" }}>
                                <div
                                    style={{
                                        display: "flex",
                                        justifyContent: "center"
                                    }}
                                    onClick={() => toggleSublink(link.headerName)}
                                >
                                    {link.headerName}
                                    {showSublinks ?
                                        <ExpandLess />
                                        :
                                        <ExpandMore />
                                    }
                                </div>
                                {showSublinks && (
                                    <div
                                        style={{
                                            marginTop: "0.5rem",
                                            fontSize: 14,
                                            gap: "0.5rem",
                                            display: "flex",
                                            flexDirection: "column",
                                            width: "100%",
                                            padding: "10px 0",
                                            backgroundColor: "#3e495a"
                                        }}
                                    >
                                        {sublinks}
                                    </div>
                                )
                                }

                            </div>

                        )
                    }
                    else {
                        return (<Link className="link-header text-center" key={link.url + link.name} activeClassName="font-bold link-header" to={link.url}>{link.name}</Link>);
                    }
                })}
                {showSearch ? (<Link to="/search" id="search-smartphone" alt="search" className="link-header" key={t("menu_search")} activeClassName="font-bold link-header">{t("menu_search")}</Link>) : ""}
                {linkPass != null ? (<a href={linkPass}>
                    <Button variant="contained" role="link" color="secondary" >{t('register-pass')}</Button>
                </a>) : ""}
                {isSENR ? (<Link to={`/`} aria-label="Logo Numérique Responsable"><img loading="lazy" className="flex" alt="Logo Numérique Responsable" width={"50"} height="50" src={logoNR}></img></Link>) : "" }
            </div>
        </div>

    )
}

export default NavBar;